<template>
  <div class="container">
    <header-back name='我的佣金'></header-back>
    <section class="top44">
      <div class="team-cen">

        <div class="team1">
          <!-- <div class="team2-t">
            <div class="team2-tl">
              <div class="tem2-t-text">{{userInfos.nickName}}</div>
              <div>{{userType}}级代理</div>
            </div>
            <div class="team2-tr">
              <div class="tem2-t-text">状态</div>
              <div>
                <van-switch
                  disabled
                  v-model="checked"
                  active-color="#07c160"
                  size="20px"
                />
              </div>
            </div>
          </div> -->
          <div class="team2-m">
            <div class="team2-head">

              <div
                class="team2-th"
                v-for="(item,index) in head"
                :key='index'
              >{{item}}</div>

            </div>
            <div
              class="team2-m-cen"
              v-if="headList.length"
            >

              <div
                class="team2-list"
                v-for="(item,index) in headList"
                :key='index'
              >
                <div class="team2-list-name">{{item.discountName}}</div>
                <div class="team2-list-def"> <span>{{item.userDiscountAmount}} %</span></div>

              </div>

            </div>
            <div
              class="team2-m-cen"
              v-else
            >
              <p style="text-align: center;padding:20px;color:#999;font-size:12px">暂无数据</p>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      checked: true,
      head: ["渠道", "我的折扣"],
      headList: [],
    };
  },
  computed: {
    userType() {
      return this.$store.getters["user/userType"];
    },
    userInfos() {
      return this.$store.getters["user/userInfos"];
    },
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      API.getTeamProductList().then((res) => {
        let list = res.data || [];
        // updatePermission：0 可改 1 不可改
        this.headList = list.map((item) => {
          return {
            ...item,
            userDiscountAmount: item.userDiscountAmount * 100,
            childUserDiscountAmount: 0.0,
            checked: true,
          };
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
}
.team-cen {
  .van-search__content {
    background-color: #fff;
  }
}
.team1 {
  box-sizing: border-box;

  color: #3e3e3e;
  font-size: 16px;
}
.team1 {
  .team2-m {
    background: #fff;
    border-radius: 6px;
    margin-top: 16px;
  }
}
.team2-h,
.team2-head,
.team2-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.team2-ht {
  flex: 1;
  height: 45px;
  line-height: 45px;
  color: #717171;
  background: #e3e3e3;
  font-size: 14px;
}
.htActive {
  background-color: #e72d94;
  color: #fff;
}
.team2-th {
  flex: 1;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e5e5e5;
}
.team2-list {
  height: 45px;
  line-height: 45px;
}
.team2-list:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}
.team2-list > div {
  flex: 1;
  font-size: 12px;
}
.team2-list-def > span {
  background-color: #b6c1fa;
  color: #fff;
  padding: 6px 15px;
}
.team2-t {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 120px;
}
.team2-t > div {
  flex: 1;
  font-size: 14px;
  color: #7e7e7e;
}
.tem2-t-text {
  font-size: 16px;
  color: #3e3e3e;
  padding-bottom: 6px;
}
.team2-tl {
  position: relative;
  border-right: 1px solid #e4e4e4;
}
</style>
